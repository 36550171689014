@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Dosis:200,400,500,600);
@charset "UTF-8";

.brutal-widget{
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    
    color: white;
    bottom: 15px;
    right: 20px;
    
    z-index: 999999;
    box-shadow: 0px 3px 19px 0px rgba(0,0,0,0.3);
    
    -webkit-transition: all 0.5s ease;
    
    transition: all 0.5s ease;
    cursor: pointer;
}
.brutal-widget:hover {
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.6);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
 
}

.brutal-widget #open, .brutal-widget #close {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 100px;
    background-color: #6F4EB9;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    
}

.brutal-widget #open:hover, .brutal-widget #close:hover {
    background-color: #8661d8;
    
}

.brutal-widget #open {
    background-image: url(/static/media/audio-feedback-open-button.35776a48.svg);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.brutal-widget #close {
    background-image: url(/static/media/audio-feedback-close-button.cfd121e2.svg);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.brutal-panel{
    width: 200px;
    height: 200px;
    background-color: #6F4EB9;
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 90px;
    left: 20px;
    border-radius: 8px;
    z-index: 999999;
    
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.2);
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    -webkit-animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.btn-start-audio-recording{
    width: 50px;
    height: 50px;
    background-color: #F85246;
    background-image: url(/static/media/audio-feedback-open-button.35776a48.svg);
    border: 2px solid #4e328b;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-stop-audio-recording{
    width: 50px;
    height: 50px;
    background-color: white;
    background-image: url(/static/media/audio-feedback-stop-button.30a18ad8.svg);
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #4e328b;
    color: red!important;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-status {
    opacity: 0.5;
    font-size: 90%;
}

.countdown {
    font-size: 80%;
    margin-top: 0;
    margin-bottom: 0;
}

#brutal-audiofeedback-container {
    
}

.brutal-container {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 3;
    
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.d-none {
    display: none;
}



/* button styles */


.btn {
    
    font-size: 11px;
    padding: 10px 26px 10px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}


.btn-primary {
    color: #fff;
    background-color: #4676FF;
    border-color: #4676FF; }
    .btn-primary:hover {
      color: #fff;
      background-color: #3961D6;
      border-color: #3961D6;
      box-shadow: 0 1px 10px rgba(80, 161, 255, 0.4); }
    .btn-primary:focus, .btn-primary.focus {
      color: #fff;
      box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5); }
    .btn-primary.disabled, .btn-primary:disabled {
      background-color: #50a1ff;
      border-color: #50a1ff; }
    .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #278bff;
      border-color: #278bff;
      box-shadow: 0 0 0 0rem rgba(80, 161, 255, 0.5); }
  
  .btn-secondary {
    color: #79868C;
    background-color: #D7DDE7;
    border-color: #D7DDE7; }
    .btn-secondary:hover {
      color: #757575;
      background-color: #C8CDD7;
      border-color: #C8CDD7;
      box-shadow: 0 1px 10px rgba(233, 236, 240, 0.4); }
    .btn-secondary:focus, .btn-secondary.focus {
      color: #79868C;
      box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5); }
    .btn-secondary.disabled, .btn-secondary:disabled {
      background-color: #e9ecf0;
      border-color: #e9ecf0; }
    .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      color: #757575;
      background-color: #d1d7df;
      border-color: #d1d7df;
      box-shadow: 0 0 0 0rem rgba(233, 236, 240, 0.5); }
  
  .btn-success {
    color: #fff;
    background-color: #00B796;
    border-color: #00B796; }
    .btn-success:hover {
      color: #fff;
      background-color: #2dce4b;
      border-color: #2dce4b;
      box-shadow: 0 1px 10px rgba(60, 212, 88, 0.4); }
    .btn-success:focus, .btn-success.focus {
      color: #fff;
      box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5); }
    .btn-success.disabled, .btn-success:disabled {
      background-color: #3cd458;
      border-color: #3cd458; }
    .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
    .show > .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #2abd45;
      border-color: #2abd45;
      box-shadow: 0 0 0 0rem rgba(60, 212, 88, 0.5); }
  
  .btn-info {
    color: #fff;
    background-color: #8A69D4;
    border-color: #8A69D4; }
    .btn-info:hover {
      color: #fff;
      background-color: #6E4FB6;
      border-color: #6E4FB6;
      box-shadow: 0 1px 10px rgba(146, 109, 222, 0.4); }
    .btn-info:focus, .btn-info.focus {
      color: #fff;
      box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5); }
    .btn-info.disabled, .btn-info:disabled {
      background-color: #926dde;
      border-color: #926dde; }
    .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
    .show > .btn-info.dropdown-toggle {
      color: #fff;
      background-color: #794cd6;
      border-color: #794cd6;
      box-shadow: 0 0 0 0rem rgba(146, 109, 222, 0.5); }
  
  .btn-warning {
    color: #fff;
    background-color: #FAA62B;
    border-color: #FAA62B; }
    .btn-warning:hover {
      color: #fff;
      background-color: #DF9322;
      border-color: #DF9322;
      box-shadow: 0 1px 10px rgba(255, 186, 0, 0.4); }
    .btn-warning:focus, .btn-warning.focus {
      color: #fff;
      box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5); }
    .btn-warning.disabled, .btn-warning:disabled {
      background-color: #ffba00;
      border-color: #ffba00; }
    .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
    .show > .btn-warning.dropdown-toggle {
      color: #fff;
      background-color: #d69c00;
      border-color: #d69c00;
      box-shadow: 0 0 0 0rem rgba(255, 186, 0, 0.5); }
  
  .btn-danger {
    color: #fff;
    background-color: #F85246;
    border-color: #F85246; }
    .btn-danger:hover {
      color: #fff;
      background-color: #CF4137;
      border-color: #CF4137;
      box-shadow: 0 1px 10px rgba(255, 73, 84, 0.4); }
    .btn-danger:focus, .btn-danger.focus {
      color: #fff;
      box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5); }
    .btn-danger.disabled, .btn-danger:disabled {
      background-color: #ff4954;
      border-color: #ff4954; }
    .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
    .show > .btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #ff202e;
      border-color: #ff202e;
      box-shadow: 0 0 0 0rem rgba(255, 73, 84, 0.5); }


input {
    width: -webkit-fill-available;
    height: 40px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #b4b9c2;
    padding: 0 10px;
    font-size: 100%;
}

#waveform {
    opacity: 0.4;
    width: 100%;
}

.record-block select {
    width: 30%;
    margin-top: 10px;
    background-color: transparent;
    color: #b8a3e9;
    border: 0px;
    font-size: 70%;
    display: block;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -15px;
    z-index: 500;
    position: relative;
}

.powered-brutal {
    width: 70px;
    height: 14px;
    background-image: url(/static/media/powered-brutal.ec0345f2.svg);
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    background-size: contain;
    position: absolute;
    bottom: 5px;
    opacity: 0.4;
}

.email-notice {
    font-size: 75%;
    opacity: 0.5;
    margin-top: 0;
    margin-bottom: 1.2rem;
}
